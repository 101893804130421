import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {ValioCartEntryUpdateData} from "../../../../../services/cart/valio-cart.objects";
import {ValioCartService} from "../../../../../services/cart/valio-cart.service";
import {ValioVendorService} from "../../../../../services/vendor/valio-vendor.service";
import {Vendor} from "../../../../../models/misc.model";
import {ValioProduct, ValioUnit} from "../../../../../models";
import {ValioSearchBoxComponentService} from "./valio-search-box-component.service";
import {map} from "rxjs/operators";

const DEFAULT_PRODUCT: ValioProduct = {name: ""};

@Component({
  selector: 'valio-suggestive-search',
  templateUrl: './valio-suggestive-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioSuggestiveSearchComponent implements OnInit, OnDestroy {
  @Output() addToCartEmitter: EventEmitter<any> = new EventEmitter();
  @Input() baseOrderMode = false;
  @Input() templateMode = false;
  selectedPartner: Vendor;
  selectedProduct: ValioProduct = DEFAULT_PRODUCT;
  partners$: Observable<Vendor[]>;
  selectedUnit: ValioUnit = {};
  selectedQty: number = 1;
  selectedPoNumber: string;
  valueSetter = new BehaviorSubject(this.selectedQty);
  addButtonEnabled: boolean = true;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected cartService: ValioCartService,
    protected vendorService: ValioVendorService,
    protected searchBoxComponentService: ValioSearchBoxComponentService,
    protected cdr: ChangeDetectorRef
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  ngOnInit() {
    this.partners$ = this.vendorService.getActiveVendors().pipe(
      map(vendors => vendors
        .filter(vendor => {
          return this.baseOrderMode ? vendor.activeContract && vendor.baseOrdersEnabled : vendor.orderingAllowed;
        }))
    );
  }

  updateQuantity(data: ValioCartEntryUpdateData) {
    if (data.enterPressed) {
      this.addToCart();
    } else {
      this.selectedQty = data.qty;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  enableAddButton(data: boolean) {
    this.addButtonEnabled = data;
  }

  async addToCart() {
    const start = new Date().getTime();
    while (new Date().getTime() - start < 5000 && !this.addButtonEnabled) {
      await this.delay(200);
    }
    if (this.selectedProduct !== DEFAULT_PRODUCT) {
      this.addToCartEmitter.emit({code: this.selectedProduct.code, item: this.selectedProduct, data: {qty: this.selectedQty, poNumber: this.selectedPoNumber, quickAdd: this.selectedProduct.name == null}});
      this.clearState();
    }
    document.getElementById('searchInputField').focus();
  }

  clearState() {
    this.selectedUnit = {};
    this.selectedProduct = DEFAULT_PRODUCT;
    this.searchBoxComponentService.clearResults();
    this.selectedQty = 1;
    this.valueSetter.next(this.selectedQty);
    this.cdr.detectChanges();
  }

  selectProduct(product: ValioProduct) {
    this.selectedProduct = product;
    this.selectedUnit = product.unit;
    this.cdr.detectChanges();
  }

  selectPartner(partner: Vendor) {
    this.selectedPartner = partner;
    this.cdr.detectChanges();
  }

  selectPoNumber(event) {
    this.selectedPoNumber = event.target.value;
  }
}
