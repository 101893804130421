import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {ValioCartService} from "../../../../services/cart/valio-cart.service";
import {ValioVendorService} from "../../../../services/vendor/valio-vendor.service";
import {ValioBaseOrder, ValioBaseOrderEntry, ValioBaseWeekdayEntry} from "../../../../services/cart/valio-cart.objects";
import {ValioSuggestiveSearchComponent} from "../../cart/cart-detail/suggestive-search/valio-suggestive-search.component";
import {ValioSearchBoxComponentService} from "../../cart/cart-detail/suggestive-search/valio-search-box-component.service";
import {ValioProduct} from "../../../../models";

@Component({
  selector: 'valio-baseorder-search',
  templateUrl: './valio-baseorder-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValioBaseorderSearchComponent extends ValioSuggestiveSearchComponent {
  evenOdd = 'EVERY_WEEK';
  @Input() allowedWeekDays?: number[];

  weekDays = [2, 3, 4, 5, 6, 7, 1];

  codeError = false;
  weekDayError = false;

  constructor(
    protected cartService: ValioCartService,
    protected vendorService: ValioVendorService,
    protected searchBoxComponentService: ValioSearchBoxComponentService,
    protected cdr: ChangeDetectorRef
  ) {
    super(cartService, vendorService, searchBoxComponentService, cdr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkErrors();
  }

  async addToCart() {
    const selectedWeekdays = document.getElementsByClassName('active-weekday');
    const baseOrder: ValioBaseOrder = {
      entries: []
    };
    if (this.selectedProduct.code && selectedWeekdays.length > 0) {
      for (let i = 0; i < selectedWeekdays.length; ++i) {
        baseOrder.entries.push({
          weekday: parseInt(selectedWeekdays[i].getAttribute("data-day")),
          entries: [
            {
              evenOddWeek: this.evenOdd,
              product: {...this.selectedProduct},
              quantity: this.selectedQty,
              poNumber: this.selectedPoNumber,
              unit: this.selectedProduct.unit,
              schoolMilkAllowed: this.selectedProduct.schoolMilkScheme
            } as ValioBaseOrderEntry
          ]
        } as ValioBaseWeekdayEntry);
      }
      this.addToCartEmitter.emit(baseOrder);
      this.clearState();
    }
    this.selectedQty = 1;
    this.valueSetter.next(this.selectedQty);
    this.checkErrors()
    this.cdr.detectChanges();
  }

  toggleSelector(weekDayId: number) {
    if (this.isWeekdayAllowed(weekDayId)) {
      document.getElementById('weekDay_' + weekDayId).classList.toggle('active-weekday');
    }
    this.checkErrors();
  }

  selectEvenOdd(val: string) {
    this.evenOdd = val;
  }

  isWeekdayAllowed(weekday: number): boolean {
    return this.allowedWeekDays
      .filter(w => w == weekday)
      .includes(weekday);
  }

  selectProduct(product: ValioProduct) {
    super.selectProduct(product);
    this.checkErrors();
  }

  checkErrors() {
    this.codeError = false;
    this.weekDayError = false;
    if (!this.selectedProduct.code) {
      this.codeError = true;
    }
    const selectedWeekdays = document.getElementsByClassName('active-weekday');
    if (selectedWeekdays.length == 0) {
      this.weekDayError = true;
    }
  }
}
