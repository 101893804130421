import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
  ValioBaseOrder,
  ValioBaseOrderBlock,
  ValioBaseOrderEntry,
  ValioBaseWeekdayEntry,
  ValioCartEntryUpdateData
} from "../../../../services/cart/valio-cart.objects";
import {ValioOrderTemplatesService} from "../../../../services/orderTemplates/valio-order-templates.service";
import {Observable, Subscription} from "rxjs";
import {LAUNCH_CALLER, LaunchDialogService} from "@spartacus/storefront";
import {first, map} from "rxjs/operators";
import {DateUtils} from "../../../misc/util/date-utils";

@Component({
  selector: 'valio-cx-baseorder-content',
  templateUrl: './valio-baseorder.component.html',
})
export class ValioBaseorderContentComponent implements OnInit, OnDestroy {
  baseorder$: Observable<ValioBaseOrder>;
  baseOrderBlocks$: Observable<ValioBaseOrderBlock[]>;
  allowedWeekDays: number[];
  showBlocks = false;
  enableSaveBtn = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    protected orderTemplatesService: ValioOrderTemplatesService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService
  ) {
  }

  ngOnInit(): void {
    this.baseorder$ = this.orderTemplatesService.getBaseOrder()
    this.baseOrderBlocks$ = this.baseorder$
        .pipe(
          map(baseOrder=> baseOrder.blocks),
          map((baseOrderBlocks:ValioBaseOrderBlock[]) => {
            return baseOrderBlocks.filter(block => this.isBlockVisible(block))
          })
        );
    this.subscriptions.add(
      this.baseorder$.subscribe(bo => {
        if (bo != null) {
          this.allowedWeekDays = bo.allowedWeekDays;
          this.cdr.detectChanges();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  isBlockVisible(baseOrderBlock:ValioBaseOrderBlock): boolean {
    const now:Date = DateUtils.getNow();
    const blockStart = DateUtils.convertDate(baseOrderBlock.blockStart);
    const blockEnd = DateUtils.convertDate(baseOrderBlock.blockEnd);
    return blockStart >= now || (DateUtils.isBetween(now, blockStart, blockEnd));
  }

  updateBaseOrder(baseOrder: ValioBaseOrder, data: {
    item: ValioBaseOrderEntry,
    data: ValioCartEntryUpdateData
  }): void {
    baseOrder = {
      ...baseOrder,
      entries: baseOrder.entries
        .map(w => ({
              ...w,
              entries: w.entries
                .map(e => {
                  const newE = {...e};
                  if (data.item.entryNumber == e.entryNumber) {
                    if (data.data.qty > 0) {
                      newE.quantity = data.data.qty;
                    } else {
                      newE.deleted = true;
                    }
                    if (data.data.poNumber) {
                      newE.poNumber = data.data.poNumber;
                    }
                    newE.schoolMilkSupport = data.data.schoolMilkSupport;
                  }
                  return newE;
                })
                .filter(e => e.product != null)
            }
          )
        )
    };
    this.checkBaseOrder(baseOrder);
    this.enableSaveBtn = true;
  }

  addToBaseOrder(newBo: ValioBaseOrder): void {
    this.subscriptions.add(
      this.baseorder$.pipe(first()).subscribe(
        baseorder => {
          const outgoingBaseorder: ValioBaseOrder = {...baseorder, entries: [...baseorder.entries]};
          outgoingBaseorder.entries.splice(0, 0, ...newBo.entries);
          this.checkBaseOrder(outgoingBaseorder);
        }));
    this.enableSaveBtn = true;
  }

  checkBaseOrder(baseOrder: ValioBaseOrder): void {
    this.orderTemplatesService.checkBaseOrder(baseOrder)
  }

  saveBaseOrder(baseOrder: ValioBaseOrder): void {
    this.orderTemplatesService.updateBaseOrder(baseOrder);
    this.enableSaveBtn = false;
  }

  calculateItems(baseOrder: ValioBaseOrder, weekEntry: ValioBaseWeekdayEntry): number {
    let count = 0;
    baseOrder.entries
      .filter(e => e.weekday == weekEntry.weekday)
      .map(w => count += w.entries.filter(e => !e.deleted).length);
    return count;
  }

  addBlock(baseOrder: ValioBaseOrder): void {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.BASE_ORDER_BLOCK_MODAL, null);

    this.subscriptions.add(this.launchDialogService.dialogClose.subscribe((block) => {
      // on dialog close
      if (block) {
        this.addBlockCallback(baseOrder, block)
      }
    }));
  }

  addBlockCallback(baseOrder: ValioBaseOrder, block: ValioBaseOrderBlock): void {
    if (block) {
      baseOrder = {...baseOrder, blocks: [...baseOrder.blocks]}
      baseOrder.blocks.push(block);
      this.saveBaseOrder(baseOrder);
    }
  }

  convertDate(d: string) {
    return new Date(d);
  }

  removeBlock(baseOrder: ValioBaseOrder, block: ValioBaseOrderBlock) {
    if (!block.entryNumber) {
      baseOrder = {
        ...baseOrder,
        blocks: baseOrder.blocks.filter(b => b != block)
      };
    } else {
      baseOrder = {
        ...baseOrder,
        blocks: baseOrder.blocks.map(b => {
          if (b.entryNumber == block.entryNumber) {
            b = {...b, deleted: true};
          }
          return b;
        })
      };
    }
    this.saveBaseOrder(baseOrder);
  }

  toggleBlocks() {
    this.showBlocks = !this.showBlocks;
  }

  getActiveItems(weekEntry: ValioBaseWeekdayEntry): ValioBaseOrderEntry[] {
    return weekEntry.entries.filter(e => !e.deleted);
  }
}
