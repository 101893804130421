import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CartActions} from "@spartacus/cart/base/core";
import {RoutingService} from "@spartacus/core";
import {Observable, of} from "rxjs";
import {catchError, concatMap, map} from "rxjs/operators";
import {ValioCartService} from "../cart/valio-cart.service";
import {openCloseSpinner} from "../util/valio-modals-utils";
import {
    BASE_ORDER_LOAD,
    BASE_ORDER_SAVE,
    BASE_ORDER_SIMULATE,
    ORDER_TEMPLATES_CREATE,
    ORDER_TEMPLATES_LIST,
    ORDER_TEMPLATES_REMOVE,
    ORDER_TEMPLATES_SAVE,
    ValioBaseOrderLoad,
    ValioBaseOrderLoadFail,
    ValioBaseOrderLoadSuccess,
    ValioBaseOrderSave,
    ValioBaseOrderSimulate,
    ValioOrderTemplateCreate,
    ValioOrderTemplateFail,
    ValioOrderTemplateList,
    ValioOrderTemplateRemove,
    ValioOrderTemplateSave,
    ValioOrderTemplateSuccess
} from "./valio-order-template.action";
import {ValioOrderTemplatesConnector} from "./valio-order-templates.connector";


@Injectable()
export class ValioOrderTemplateEffects {

    constructor(
        private actions$: Actions,
        private orderTemplatesConnector: ValioOrderTemplatesConnector,
        protected cartService: ValioCartService,
        protected routingService: RoutingService
    ) {
    }

    loadOrderTemplates$: Observable<ValioOrderTemplateFail | ValioOrderTemplateSuccess> = createEffect(() => this.actions$.pipe(
            ofType(ORDER_TEMPLATES_LIST),
            map((action: ValioOrderTemplateList) => action),
            concatMap(action => {
                    return this.orderTemplatesConnector.getOrderTemplates(action.userId).pipe(
                        map(list => new ValioOrderTemplateSuccess(list))
                    );
                }
            ), catchError(error => {
                console.error("Error in loadOrderTemplates$: " + error);
                return of(new ValioOrderTemplateFail(true));
            })
        )
    );

    removeOrderTemplate$: Observable<ValioOrderTemplateFail | ValioOrderTemplateSuccess> = createEffect(() => this.actions$.pipe(
            ofType(ORDER_TEMPLATES_REMOVE),
            map((action: ValioOrderTemplateRemove) => action),
            concatMap(action => {
                    return this.orderTemplatesConnector.removeOrderTemplate(action.userId, action.templateId).pipe(
                        map(list => new ValioOrderTemplateSuccess(list))
                    );
                }
            ), catchError(error => {
                console.error("Error in removeOrderTemplate$: " + error);
                return of(new ValioOrderTemplateFail(true));
            })
        )
    );

    createOrderTemplate$: Observable<ValioOrderTemplateFail | ValioOrderTemplateSuccess> = createEffect(() => this.actions$.pipe(
            ofType(ORDER_TEMPLATES_CREATE),
            map((action: ValioOrderTemplateCreate) => action),
            concatMap(action => {
                    return this.orderTemplatesConnector.createOrderTemplate(action.userId, action.name, action.copyId, action.copyDate).pipe(
                        concatMap(list => {
                            const code = list[list.length - 1].code;
                            this.routingService.go("my-account/modify-order-template/" + code);
                            return [new ValioOrderTemplateSuccess(list, code)];
                        })
                    );
                }
            ), catchError(error => {
                console.error("Error in createOrderTemplate$: " + error);
                return of(new ValioOrderTemplateFail(true, true));
            })
        )
    );


    saveOrderTemplate$: Observable<ValioOrderTemplateFail | ValioOrderTemplateSuccess | CartActions.ResetCartDetails> = createEffect(() => this.actions$.pipe(
            ofType(ORDER_TEMPLATES_SAVE),
            map((action: ValioOrderTemplateSave) => action),
            concatMap(action => {
                    openCloseSpinner(true);
                    return this.orderTemplatesConnector.saveOrderTemplate(action.userId, action.cartId, action.orderTemplate, action.updateCart).pipe(
                        map(list => {
                            if (action.cartId == action.orderTemplate.code) {//add to template
                                // State won't refresh until page is changed. Go back to templates page and select current template again
                                this.routingService.go("my-account/order-templates")
                                this.routingService.go("my-account/modify-order-template/" + action.orderTemplate.code);
                                return new ValioOrderTemplateSuccess(list);
                            } else {//add to cart
                                this.routingService.go("cart");
                                openCloseSpinner(false);
                                return new CartActions.ResetCartDetails();
                            }
                        })
                    );
                }
            ), catchError(error => {
                console.error("Error in saveOrderTemplate$: " + error);
                return of(new ValioOrderTemplateFail(true, true));
            })
        )
    );


    loadOrderBaseOrder$: Observable<ValioBaseOrderLoadSuccess | ValioBaseOrderLoadFail> = createEffect(() => this.actions$.pipe(
            ofType(BASE_ORDER_LOAD),
            map((action: ValioBaseOrderLoad) => action),
            concatMap(action => {
                    openCloseSpinner(true);
                    return this.orderTemplatesConnector.getBaseOrder(action.userId).pipe(
                        map(bo => {
                            openCloseSpinner(false);
                            return new ValioBaseOrderLoadSuccess(bo);
                        })
                    );
                }
            ), catchError(error => {
                console.error("Error in loadOrderBaseOrder$: " + error);
                return of(new ValioBaseOrderLoadFail());
            })
        )
    );


    simulateOrderBaseOrder$: Observable<ValioBaseOrderLoadSuccess | ValioBaseOrderLoadFail> = createEffect(() => this.actions$.pipe(
            ofType(BASE_ORDER_SIMULATE),
            map((action: ValioBaseOrderSimulate) => action),
            concatMap(action => {
                    openCloseSpinner(true);
                    return this.orderTemplatesConnector.checkBaseOrder(action.userId, action.baseOrder).pipe(
                        map(bo => {
                            openCloseSpinner(false);
                            return new ValioBaseOrderLoadSuccess(bo);
                        })
                    );
                }
            ), catchError(error => {
                console.error("Error in simulateOrderBaseOrder$: " + error);
                return of(new ValioBaseOrderLoadFail());
            })
        )
    );


    saveOrderBaseOrder$: Observable<ValioBaseOrderLoadSuccess | ValioBaseOrderLoadFail> = createEffect(() => this.actions$.pipe(
            ofType(BASE_ORDER_SAVE),
            map((action: ValioBaseOrderSave) => action),
            concatMap(action => {
                    openCloseSpinner(true);
                    return this.orderTemplatesConnector.updateBaseOrder(action.userId, action.baseOrder).pipe(
                        map(bo => {
                            openCloseSpinner(false);
                            return new ValioBaseOrderLoadSuccess(bo);
                        })
                    );
                }
            ), catchError(error => {
                console.error("Error in saveOrderBaseOrder$: " + error);
                return of(new ValioBaseOrderLoadFail());
            })
        )
    );
}

